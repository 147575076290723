export const useMerchantStore = defineStore('merchant', {
  state: () => {
    return { 
      merchant: null, 
    }
  },
  
  actions: {
    setMerchant(info) {
      this.merchant = info
    },
  },
})